import React from 'react'

import styled from 'styled-components'

import Layout from '../../components/layout'

import { MainSectionWrapper } from '../../components/common/styles'

const MainSectionWrapperOverride = styled(MainSectionWrapper)`
    grid-row: 3 / 5;
`

const Wrapper = styled.form`
    width: 75%;
    margin: 0 auto;

    & > * {
        display: block;
        margin: 15px;
    }

    & input[type=text] {
        box-sizing: border-box;
    }

    & label {
        display: block;
        text-align: initial;
    }

    & p {
        text-align: justify;
    }
`

const Input = styled.input`
    margin: 5px 0;
    padding: 5px;

    ${props => props.width ? `width: ${props.width};` : ''}
    
    height: 35px;

    border: 1px solid transparent;
    border-top: none;
    border-bottom: 1px solid #DDD;
    box-shadow: inset 0 1px 2px rgba(0,0,0,.39), 0 -1px 1px #FFF, 0 1px 0 #FFF;
`

const TextArea = styled.textarea`
    margin: 5px 0;
    padding: 10px;

    ${props => props.width ? `width: ${props.width};` : ''}

    height: 150px;

    border: 1px solid transparent;
    border-top: none;
    border-bottom: 1px solid #DDD;
    box-shadow: inset 0 1px 2px rgba(0,0,0,.39), 0 -1px 1px #FFF, 0 1px 0 #FFF;
`

const Button = styled.button`
    width: 125px;
    height: 40px;
    text-transform: uppercase;

    background: inherit;

    color: #98c8f0;

    border: 1px solid #98c8f0;
    border-radius: 20px;

    cursor: pointer;

    &:hover {
        background: #98c8f0;
        border: 1px solid transparent;
        color: white;
    }
`

const ContactForm = () => (
    <Layout>
        <MainSectionWrapperOverride>
            <Wrapper>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '48%' }}>
                        <label for="first-name">First Name *</label>
                        <Input name="first-name" width='100%' />
                    </div>
                    <div style={{ width: '48%' }}>
                        <label for="last-name">Last Name *</label>
                        <Input name="last-name" width='100%' />
                    </div>
                </div>
                <div>
                    <label for="email-address">Email Address *</label>
                    <Input name="email-address" width='100%' />
                </div>
                <div>
                    <label for="subject">Subject *</label>
                    <Input name="subject" width='100%' />
                </div>
                <div>
                    <label for="message">Message *</label>
                    <TextArea name="message" style={{ width: '100%' }} />
                </div>
                <Button>Submit</Button>
            </Wrapper>
        </MainSectionWrapperOverride>
    </Layout>
)

export default ContactForm